import { secrets } from '@chilipiper/config'
import { store } from '@chilipiper/utils'
import { isCypress, isServiceWorker } from '@chilipiper/utils'
import { RoxSetupOptions } from 'rox-browser'
import Rox from './rollout-mock/rolloutMock'

export const isRolloutEnabled =
  !isCypress &&
  !isServiceWorker &&
  process.env.NODE_ENV !== 'development' &&
  !!secrets.rolloutApiKey

const mockRolloutFlags = {
  enableDistributionWorkspace: new Rox.Flag(true),
  enableHandoff: new Rox.Flag(true),
  enableOpenRegistrationSeries: new Rox.Flag(false),
  enableSignature: new Rox.Flag(true),
  enableUTMTracking: new Rox.Flag(true),
  enableSingleUseLink: new Rox.Flag(true),
  enableAddGuestsToCrmSidebar: new Rox.Flag(true),
  enableMeetingLocation: new Rox.Flag(true),
  newIbToggle: new Rox.Flag(true),
  enableMaintenance: new Rox.Flag(false),
  enableMeetingsGroupEvents: new Rox.Flag(false),
  enableReassignDropdown: new Rox.Flag(true),
  enableDashboardCancel: new Rox.Flag(true),
  enableHopinIntegration: new Rox.Flag(true),
  enableMsWithoutConsent: new Rox.Flag(false),
  enableExperimentalDarkMode: new Rox.Flag(true),
  enableNewDistributionReport: new Rox.Flag(true),
  enableNewDistributionReportHistoryTab: new Rox.Flag(true),
  enableFlowBuilderRelativeDates: new Rox.Flag(true),
  enableDistroFallback: new Rox.Flag(true),
  enableDistroCapping: new Rox.Flag(true),
  enableDistroDistributionAsAsset: new Rox.Flag(true),
  enableGroupLinksInScheduler: new Rox.Flag(true),
  enableEventHistory: new Rox.Flag(true),
  enableHandoffLiveFlowBuilderNew: new Rox.Flag(true),
  fireNavigation: new Rox.Flag(true),
  inAppLicensesSell: new Rox.Flag(true),
  forceDisplayInvoiceFlow: new Rox.Flag(true),
  fireTeamsMigration: new Rox.Flag(true),
  enableAdminCenterSettings: new Rox.Flag(false),
  hubspotIntegrationUser: new Rox.Flag(false),
  fireAuth: new Rox.Flag(true),
  chatUnreadBadge: new Rox.Flag(true),
  chilicalPreviewModal: new Rox.Flag(false),
  distroPreviewModal: new Rox.Flag(false),
  handoffPreviewModal: new Rox.Flag(false),
  conciergePreviewModal: new Rox.Flag(false),
  billingCenter: new Rox.Flag(true),
  changeBillingPeriod: new Rox.Flag(true),
  switchToPayByInvoice: new Rox.Flag(true),
  cancelSubscription: new Rox.Flag(true),
  managerRoles: new Rox.Flag(true),
  workspaceManagerRole: new Rox.Flag(true),
  notificationSettings: new Rox.Flag(true),
  distroOwnershipFieldsInUpdateOwnership: new Rox.Flag(true),
  brokenIntegrationsBanner: new Rox.Flag(true),
  notifyAllButton: new Rox.Flag(true),
  playbookBuilderBookMeetingBlock: new Rox.Flag(true),
  branding: new Rox.Flag(true),
  userDetails: new Rox.Flag(true),
  adminLicense: new Rox.Flag(false),
  createTaskEnabled: new Rox.Flag(true),
  createOpportunityEnabled: new Rox.Flag(true),
  conversationDistribution: new Rox.Flag(true),
  bundleChiliCal: new Rox.Flag(true),
  ringCentralIntegration: new Rox.Flag(true),
  webexIntegration: new Rox.Flag(true),
  goToMeetingIntegration: new Rox.Flag(true),
  l2lDuplicationMatching: new Rox.Flag(true),
  chatAnalytics: new Rox.Flag(true),
  chatNodeExecutionDebugger: new Rox.Flag(true),
  chatConsentFooter: new Rox.RoxString(
    `Chili Piper provides this chat. You agree this chat may be recorded. See Chili Piper's Privacy Statement.`
  ),
  chatConsentFooterLink: new Rox.RoxString('https://www.chilipiper.com/privacy-policy'),
  relatedLeadMatching: new Rox.Flag(true),
  mergeRecordsAction: new Rox.Flag(true),
  outreachIntegration: new Rox.Flag(true),
  salesloftIntegration: new Rox.Flag(true),
  opportunityCaseMatching: new Rox.Flag(true),
  customMatching: new Rox.Flag(true),
  handoffUserControls: new Rox.Flag(true),
  distributionGroups: new Rox.Flag(true),
  distributionCherryPickEdit: new Rox.Flag(true),
  distributionPickAssignee: new Rox.Flag(true),
  distroRouterExplicitRelations: new Rox.Flag(true),
  distroRouterVersionHistory: new Rox.Flag(true),
  navigationLockedByUserLicense: new Rox.Flag(true),
  retryRouting: new Rox.Flag(true),
  meetingTypeProspectBookingLimit: new Rox.Flag(true),
  meetingTypeHostMeetingLimit: new Rox.Flag(true),
  meetingTypeForceGuestTimezone: new Rox.Flag(true),
  schedulingLinkHostMeetingLimit: new Rox.Flag(true),
  keycloakSSO: new Rox.Flag(false),
  adminSSO: new Rox.Flag(true),
  conciergeSharedUpsertRecord: new Rox.Flag(true),
  distroRouterNotes: new Rox.Flag(true),
  distroRouterMultiFields: new Rox.Flag(true),
  groupSchedulinkLinks: new Rox.Flag(true),
  ownershipLinks: new Rox.Flag(true),
  distroAccountOpportunityTeam: new Rox.Flag(true),
  ssoEnforcement: new Rox.Flag(true),
  meetingCreatedInOption: new Rox.Flag(true),
  enableRulesMeetingObject: new Rox.Flag(true),
  conciergeIgnoreContacts: new Rox.Flag(true),
  apiAccessTokens: new Rox.Flag(true),
  tenantSlugEditable: new Rox.Flag(true),
  conciergeEnforceLanguage: new Rox.Flag(true),
  userDetailsV2: new Rox.Flag(true),
  sidebarSupportItem: new Rox.Flag(true),
  msTeamsIntegration: new Rox.Flag(true),
  conciergeLive: new Rox.Flag(true),
  utmParamsFilter: new Rox.Flag(true),
  homeTenantSelect: new Rox.Flag(true),
}
const defaultRolloutFlags: typeof mockRolloutFlags = {
  enableDistributionWorkspace: new Rox.Flag(false),
  enableHandoff: new Rox.Flag(true),
  enableOpenRegistrationSeries: new Rox.Flag(false),
  enableSignature: new Rox.Flag(true),
  enableUTMTracking: new Rox.Flag(false),
  enableSingleUseLink: new Rox.Flag(false),
  enableMeetingLocation: new Rox.Flag(false),
  enableAddGuestsToCrmSidebar: new Rox.Flag(false),
  newIbToggle: new Rox.Flag(false),
  enableMaintenance: new Rox.Flag(false),
  enableMeetingsGroupEvents: new Rox.Flag(false),
  enableReassignDropdown: new Rox.Flag(false),
  enableDashboardCancel: new Rox.Flag(false),
  enableHopinIntegration: new Rox.Flag(false),
  enableMsWithoutConsent: new Rox.Flag(false),
  enableExperimentalDarkMode: new Rox.Flag(false),
  enableNewDistributionReport: new Rox.Flag(false),
  enableNewDistributionReportHistoryTab: new Rox.Flag(false),
  enableFlowBuilderRelativeDates: new Rox.Flag(false),
  enableDistroFallback: new Rox.Flag(false),
  enableDistroCapping: new Rox.Flag(false),
  enableDistroDistributionAsAsset: new Rox.Flag(false),
  enableGroupLinksInScheduler: new Rox.Flag(false),
  enableEventHistory: new Rox.Flag(false),
  enableHandoffLiveFlowBuilderNew: new Rox.Flag(false),
  fireNavigation: new Rox.Flag(false),
  inAppLicensesSell: new Rox.Flag(false),
  forceDisplayInvoiceFlow: new Rox.Flag(false),
  fireTeamsMigration: new Rox.Flag(false),
  enableAdminCenterSettings: new Rox.Flag(false),
  hubspotIntegrationUser: new Rox.Flag(false),
  fireAuth: new Rox.Flag(true),
  chatUnreadBadge: new Rox.Flag(false),
  chilicalPreviewModal: new Rox.Flag(false),
  distroPreviewModal: new Rox.Flag(false),
  handoffPreviewModal: new Rox.Flag(false),
  conciergePreviewModal: new Rox.Flag(false),
  billingCenter: new Rox.Flag(false),
  changeBillingPeriod: new Rox.Flag(false),
  switchToPayByInvoice: new Rox.Flag(false),
  cancelSubscription: new Rox.Flag(false),
  managerRoles: new Rox.Flag(false),
  workspaceManagerRole: new Rox.Flag(false),
  notificationSettings: new Rox.Flag(false),
  distroOwnershipFieldsInUpdateOwnership: new Rox.Flag(true),
  brokenIntegrationsBanner: new Rox.Flag(false),
  notifyAllButton: new Rox.Flag(false),
  playbookBuilderBookMeetingBlock: new Rox.Flag(false),
  branding: new Rox.Flag(false),
  userDetails: new Rox.Flag(false),
  adminLicense: new Rox.Flag(false),
  createTaskEnabled: new Rox.Flag(false),
  createOpportunityEnabled: new Rox.Flag(false),
  conversationDistribution: new Rox.Flag(false),
  bundleChiliCal: new Rox.Flag(false),
  ringCentralIntegration: new Rox.Flag(false),
  webexIntegration: new Rox.Flag(false),
  goToMeetingIntegration: new Rox.Flag(false),
  l2lDuplicationMatching: new Rox.Flag(false),
  chatAnalytics: new Rox.Flag(false),
  chatNodeExecutionDebugger: new Rox.Flag(false),
  chatConsentFooter: new Rox.RoxString(
    `Chili Piper provides this chat. You agree this chat may be recorded. See Chili Piper's Privacy Statement.`
  ),
  chatConsentFooterLink: new Rox.RoxString('https://www.chilipiper.com/privacy-policy'),
  relatedLeadMatching: new Rox.Flag(false),
  mergeRecordsAction: new Rox.Flag(false),
  outreachIntegration: new Rox.Flag(false),
  salesloftIntegration: new Rox.Flag(false),
  opportunityCaseMatching: new Rox.Flag(false),
  customMatching: new Rox.Flag(false),
  handoffUserControls: new Rox.Flag(false),
  distributionGroups: new Rox.Flag(false),
  distributionCherryPickEdit: new Rox.Flag(false),
  distributionPickAssignee: new Rox.Flag(false),
  distroRouterExplicitRelations: new Rox.Flag(false),
  distroRouterVersionHistory: new Rox.Flag(false),
  navigationLockedByUserLicense: new Rox.Flag(false),
  retryRouting: new Rox.Flag(false),
  meetingTypeProspectBookingLimit: new Rox.Flag(false),
  meetingTypeHostMeetingLimit: new Rox.Flag(false),
  meetingTypeForceGuestTimezone: new Rox.Flag(false),
  schedulingLinkHostMeetingLimit: new Rox.Flag(false),
  keycloakSSO: new Rox.Flag(false),
  adminSSO: new Rox.Flag(false),
  conciergeSharedUpsertRecord: new Rox.Flag(false),
  distroRouterNotes: new Rox.Flag(false),
  distroRouterMultiFields: new Rox.Flag(false),
  groupSchedulinkLinks: new Rox.Flag(false),
  ownershipLinks: new Rox.Flag(false),
  distroAccountOpportunityTeam: new Rox.Flag(false),
  ssoEnforcement: new Rox.Flag(false),
  meetingCreatedInOption: new Rox.Flag(false),
  enableRulesMeetingObject: new Rox.Flag(false),
  conciergeIgnoreContacts: new Rox.Flag(false),
  apiAccessTokens: new Rox.Flag(false),
  tenantSlugEditable: new Rox.Flag(false),
  conciergeEnforceLanguage: new Rox.Flag(false),
  userDetailsV2: new Rox.Flag(false),
  sidebarSupportItem: new Rox.Flag(false),
  msTeamsIntegration: new Rox.Flag(false),
  conciergeLive: new Rox.Flag(false),
  utmParamsFilter: new Rox.Flag(false),
  homeTenantSelect: new Rox.Flag(false),
}

export const isFireAuth = () => rolloutFlags.fireAuth?.isEnabled() ?? false

type Flags = Record<
  keyof typeof mockRolloutFlags,
  { isEnabled(): boolean; getValue(): string | boolean }
>
type MutableFlags = Record<keyof typeof mockRolloutFlags, FlagWrapper>

const rolloutInnerFlags = isRolloutEnabled ? defaultRolloutFlags : mockRolloutFlags

class FlagWrapper {
  private value: boolean | string

  constructor(initialValue: boolean | string) {
    this.value = initialValue
  }

  isEnabled = () => !!this.value

  getValue = () => this.value

  setValue = (value: boolean | string) => {
    this.value = value
  }
}

const initFlags = (): MutableFlags =>
  Object.entries(rolloutInnerFlags).reduce<MutableFlags>((flags, [name, flag]) => {
    if (flag instanceof Rox.Flag) {
      flags[name as keyof typeof mockRolloutFlags] = new FlagWrapper(flag.isEnabled())
    } else {
      flags[name as keyof typeof mockRolloutFlags] = new FlagWrapper(flag.getValue())
    }
    return flags
  }, {} as MutableFlags)

const updateFlags = () =>
  Object.entries(rolloutInnerFlags).forEach(([name, flag]) => {
    if (flag instanceof Rox.Flag) {
      internalFlags[name as keyof typeof mockRolloutFlags].setValue(flag.isEnabled())
    } else {
      internalFlags[name as keyof typeof mockRolloutFlags].setValue(flag.getValue())
    }
  })

const internalFlags = initFlags()

export const rolloutFlags: Partial<Flags> = internalFlags

const registeredNamespaces: Set<string> = new Set()

export const rolloutSetup = async (rolloutOptions: RoxSetupOptions, namespace = '') => {
  if (registeredNamespaces.has(namespace)) {
    console.warn(
      'rolloutSetup called for a namespace that has already been registered. Skipping setup.'
    )
    return
  }

  if (isRolloutEnabled) {
    Rox.setCustomStringProperty('hostname', window.location.hostname)

    Rox.register(namespace, rolloutInnerFlags)
    registeredNamespaces.add(namespace)
    await Rox.setup(secrets.rolloutApiKey, rolloutOptions)
  }
}

export const ROLLOUT_EMAIL_LOCALSTORAGE_KEY = 'rollout-email'
export const ROLLOUT_TENANT_ID_LOCALSTORAGE_KEY = 'rollout-tenantId'

export const setRolloutUser = (session: { email?: string; tenantId?: string }): void => {
  if (isRolloutEnabled) {
    if (!store.isFake()) {
      if (session.email) {
        localStorage.setItem(ROLLOUT_EMAIL_LOCALSTORAGE_KEY, session.email)
        Rox.setCustomStringProperty('email', session.email)
      }
      if (session.tenantId) {
        localStorage.setItem(ROLLOUT_TENANT_ID_LOCALSTORAGE_KEY, session.tenantId)
        Rox.setCustomStringProperty('tenantId', session.tenantId)
      }
    }
    Rox.fetch()
  }
}

export type SetUserData = (_session: { email?: string; tenantId: string }) => Promise<void>
/*
 * The idea with this approach was to promisify setting the user data
 * which is a pre-requisite to properly use any feature flag that relies on user data.
 * Basically, we dependency inject the callbackRef object inside of rolloutInit to
 * the asyncSetRolloutUserBuilder which in turn sets the resolve function to the callback
 * of the passed callbackRef object. Resolve function is called once the configurationFetchedHandler
 * is called signalling that the user data is successfully set.
 */
const asyncSetRolloutUserBuilder = (callbackRef: { callback: () => unknown }): SetUserData => {
  return (session: { email?: string; tenantId: string }) =>
    new Promise(res => {
      callbackRef.callback = res
      setRolloutUser(session)
    })
}

const fetchRef = {
  status: '',
}

export const rolloutInit = async (appName: string): Promise<SetUserData> => {
  if (isRolloutEnabled) {
    const callbackRef = {
      callback: () => {},
    }

    const options: RoxSetupOptions = {
      version: '1.2.0',
      configurationFetchedHandler: (fetcherResult: Rox.RoxFetcherResult) => {
        fetchRef.status = fetcherResult.fetcherStatus
        updateFlags()
        callbackRef.callback()
      },
    }
    Rox.setCustomStringProperty('appName', appName)
    await rolloutSetup(options)

    // we have to get from localstorage because rollout saves only unique id for user
    if (!store.isFake()) {
      const userTenantIdCached = localStorage.getItem(ROLLOUT_TENANT_ID_LOCALSTORAGE_KEY) || ''
      const userEmailCached = localStorage.getItem(ROLLOUT_EMAIL_LOCALSTORAGE_KEY) || ''
      setRolloutUser({ email: userEmailCached, tenantId: userTenantIdCached })
    }

    await new Promise(res => {
      const interval = setInterval(() => {
        if (fetchRef.status === 'APPLIED_FROM_NETWORK') {
          clearInterval(interval)
          res(undefined)
        }
      }, 100)
    })

    return asyncSetRolloutUserBuilder(callbackRef)
  }
  return () => Promise.resolve()
}
